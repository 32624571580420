.configWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.config-parent {
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 5px;
}
.configuration {
  padding: 10px 12px;
}
// .parentConfigDiv{
//   .MuiSwitch-colorSecondary.Mui-checked{
//     color:white
//   }
//   .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
//     background-color: #003366;
//   }
//   .MuiSwitch-track{
//     opacity: 0.5;
//   }
// }
