.chemical {
  .container {
    border: 1px solid grey;
    display: block;
    align-items: center;
    padding: 0 10px;
    height: 100%;
  }
  .location-link {
    padding: 20px 10px;
    flex-grow: 1;
    text-decoration: none;
    margin: 0px;
    color: black;
  }
  .product-description {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edit-icon {
      cursor: pointer;
      padding: 3px;
      &:hover {
        background-color: $LIGHT_COLOR;
        border-radius: 50%;
      }
    }
  }
}
.filterDiv{
 display: flex;
  
}

.chemical-list {
  display: grid;
  padding: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
}

.file {
  margin: 25px;
  display: flex;
  justify-content: center;
  input {
    padding: 6px;
    border: 2px solid gainsboro;
    border-radius: 6px;
    font-weight: 600;
  }
}

.extraFields {
  display: flex;
  overflow-x: auto;
  li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 3px;
    border: 1px solid gainsboro;
    padding: 5px 3px;
    font-size: 12px;
    margin-bottom: 4px;
    color: #a6a6a6;
    width: 100px;
    height: 70px;
    white-space: nowrap;
    overflow-x: auto;
    text-overflow: ellipsis;
  }
}
