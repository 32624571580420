@import '../../theme/themeColors.scss';

$gray-color: #707070;
.tankDetails {
  display: flex;
  justify-content: space-between;
  color: $gray-color;
}
.qr-code {
  height: 100%;
  width: 100%;

  .prodcut-class {
    background-color: #707070;
    margin-bottom: 10px;
    color: white;
    padding: 4px 15px;
    border-radius: 4px;
    font-size: 15px;
    span {
      margin-right: 4px;
      font-size: 15px;
    }
  }
}
.download {
  display: flex;
  justify-content: center;
  color: $gray-color;
  padding-bottom: 20px;
  .material-icons {
    font-size: 15px;
  }
  p {
    font-size: 12px;
    font-weight: lighter;
  }
}

// .qr-code-section:hover .qrr-code {
//   opacity: 0.4;
// }
.qr-code-section {
  position: relative;
  margin: auto;
  padding: 8px;
  cursor: pointer;
}
// .qr-code-section:hover {
//   opacity: 0.8;
// }

.qrcode-section-overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.qr-code-section:hover .qrcode-section-overlay {
  opacity: 1;
}

.line {
  margin-top: 10px;
  opacity: 0.3;
}
.dialogTitle {
  font-weight: bold;
  margin-bottom: 20px;
}

.themeCardWrapper {
  width: 600px;

  padding: 20px;
}

.dialogTheme {
  display: flex;
  flex-direction: row;
  row-gap: 20px;
  justify-content: space-around;
}
.theme1 {
  padding: 10px 12px;
  text-align: center;
}

.theme1:hover {
  // background-color:#F1F1F1;
  background-color: rgba(24, 118, 208, 0.4);
  cursor: pointer;
}
.theme2 {
  padding: 10px 12px;
  text-align: center;
}

.theme2:hover {
  // background-color:#F1F1F1;
  background-color: rgba(24, 118, 208, 0.4);
  cursor: pointer;
}
.themeimage {
  width: 200px;
  height: 200px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
}

.themeTitle {
  margin-top: 10px;
  font-weight: bold;
}

.tankboxin {
  flex: 80%;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  word-break: break-word;
  grid-gap: 20px;
  margin-top: 27px;
}
.tankbox {
  display: flex;
  align-items: space-between;
  justify-content: space-around;
}
.accordianstankhead {
  width: 147px;
  height: 20px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #003366;
}
.safetydetailsblocks {
  background: rgba(238, 242, 247, 1);
  border-radius: 7px;
  padding: 20px;
  height: 120px;
}
.safetydetailsblockstext {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
.safetydetailstext {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-top: 5px;
}
.downloadqrcode {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #3298ff;
  padding-left: 10px;
}
.tankdetailschecklistphotosandcomm {
  margin-top: 59px;
  margin-bottom: 40px;
}
.accordian {
  background: #ffffff;
  border: 1px solid;
  border-color: rgba(238, 242, 247, 1);
  border-radius: 7px;
}
.productbox {
  display: flex;
  align-items: space-between;
  justify-content: space-around;
}
.productboxin {
  flex: auto;
  display: grid;
  align-items: space-between;
  justify-content: space-between;
  grid-template-columns: auto auto auto auto auto;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 25px;
  margin-right: 25px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.containingtanks {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #3298ff;
}
