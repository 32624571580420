.shipments-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 22px;

  & > * {
    @include md {
      // max-width: 30vw;
    }
  }

  .list {
    margin-top: 10px;
    border: 1px solid $DARK_COLOR;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;

    .details {
      padding: 5px 10px;
    }

    .bols {
      display: flex;
      overflow-x: auto;
      // grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));

      li {
        margin-bottom: 5px;
        list-style-type: none;
        padding: 6px;
        border: 1px solid dodgerblue;
        margin-left: 3px;
        margin-right: 3px;
        text-overflow: ellipsis;

        h5 {
          margin-top: 5px;
        }

        h6 {
          overflow-wrap: break-word;
        }
      }
    }

    .pnumber {
      font-weight: 600;
      padding: 4px 0;
    }

    p {
      color: $GREY;
      font-size: 13px;
      padding: 4px 0;
    }
    hr {
      margin: 2px 0;
    }
  }
  .list:hover {
    box-shadow: inset 0 0 5px grey;
  }
  .active {
    box-shadow: 5px 5px 0px #66ccff;
    transition: 0.5s;
  }
}
.shipments-list-new {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 22px;

  & > * {
    @include md {
      max-width: 30vw;
    }
  }

  .list {
    margin-top: 10px;
    border: 1px solid $DARK_COLOR;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;

    .details {
      padding: 5px 10px;
    }

    .bols {
      display: flex;
      overflow-x: auto;
      // grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));

      li {
        margin-bottom: 5px;
        list-style-type: none;
        padding: 6px;
        border: 1px solid dodgerblue;
        margin-left: 3px;
        margin-right: 3px;
        text-overflow: ellipsis;

        h5 {
          margin-top: 5px;
        }

        h6 {
          overflow-wrap: break-word;
        }
      }
    }

    .pnumber {
      font-weight: 600;
      padding: 4px 0;
    }

    p {
      color: $GREY;
      font-size: 13px;
      padding: 4px 0;
    }
    hr {
      margin: 2px 0;
    }
  }
  .list:hover {
    box-shadow: inset 0 0 5px grey;
  }
  .active {
    box-shadow: 5px 5px 0px #66ccff;
    transition: 0.5s;
  }
}

.shipment-list-card {
  border-radius: 7px !important;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  padding: 0px 10px 10px 10px;
  // -webkit-box-shadow: 0px 0px 17px 0px rgba(243, 243, 243, 0.76);
  // -moz-box-shadow: 0px 0px 17px 0px rgba(243, 243, 243, 0.76);
  // box-shadow: 0px 0px 17px 0px rgba(243, 243, 243, 0.76);
  &:hover {
    // box-shadow: 5px 7px 5px rgba(135, 135, 135, 0.3);
    -webkit-box-shadow: 0px 0px 17px 0px rgba(243, 243, 243, 0.76);
    -moz-box-shadow: 0px 0px 17px 0px rgba(243, 243, 243, 0.76);
    box-shadow: 0px 0px 17px 0px rgba(243, 243, 243, 0.76);
    transition: all 0.5s;
    cursor: pointer;
    // border: 1px solid #f3f3f3;
  }

  .shipment-list-orders {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-x: scroll;
    padding: 2px;
    .shipment-list-order-item {
      border-radius: 7px !important;
      min-width: 200px;
      background: #fcfcfc;
      border: 1px solid #f3f3f3;
      &:hover {
        box-shadow: 0 0 0 1px $secondaryMain !important;
        transition: 0.5s;
        border: 1px solid dodgerblue;
      }

      .shipment-order-status {
        color: $secondaryMain;
      }
      .partner-name {
        max-width: 180px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: #1a1a1a;
      }
    }
  }
  .shipment-list-orders::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}
.list-card-header {
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.shipment-card-item-data {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.shipment-card-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  color: #636363;
}

.shipment-status {
  font-weight: normal;
  font-size: 13px;
  color: #003366;
  margin-top: 8px;
}

@mixin list-card-badge {
  margin-right: 5px;
  color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 10px;
  padding: 8px;
  border-radius: 4px;
}
.list-card-badge-transit {
  @include list-card-badge;
  background-color: dodgerblue;
  color: white;
}

.list-card-badge-arrived_to_load {
  @include list-card-badge;
  background-color: #ff8637;
  color: white;
}

.list-card-badge-pre_shipment {
  @include list-card-badge;
  background-color: gray;
  color: white;
}

.list-card-badge-completed {
  @include list-card-badge;
  background-color: #a4cc2a;
  color: white;
}
.list-key-value {
  margin: 5px 10px;
}
.list-key-value h4 {
  font-weight: bold;
  margin-bottom: 5px;
}
.list-bols {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  box-sizing: border-box;
  overflow: auto;
  white-space: nowrap;
}
.bols {
  margin-top: 15px;
  border-top: 1px solid grey;
  display: flex;
  padding: 5px 0px;
  flex-direction: row;
  overflow-x: auto;
}
.shipment-button {
  background-color: #3f51b5;
  border-radius: 25px;
  margin-right: 10px;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #3f51b5;
  color: white;
}
.bols > div {
  border-radius: 4px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
  margin: 5px;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}

.bols > div {
  min-width: 100px;
  cursor: pointer;
}
.bols-headline h4 {
  font-size: 18px;

  padding-left: 5px;
  font-weight: 400;
}

.bols-headline {
  padding-left: 5px;
  font-size: 18px;
  font-weight: bold;
}
.expand {
  padding-right: 5px;
}
.filterButtonShipment {
  margin-right: 10px;
}
