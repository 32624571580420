@import 'config';

%btn {
  display: inline-block;
  padding: 8px 20px;
  border: 5px;
  border-radius: 4px;
  outline: none;
  user-select: none;
  cursor: pointer;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  &:hover {
    transform: scale(0.999);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }
  &:active {
    transform: scale(0.99);
  }
}

.btn {
  @extend %btn;
}

.btn-block {
  @extend %btn;
  width: 100%;
}
.btn-primary {
  @extend %btn;
  font-family: $PRIMARY_TEXT;
  @include set-background(lighten($PRIMARY_COLOR, 10%));
  width: 100%;
}
.btn-secondary {
  @extend %btn;
  background-color: dodgerblue;
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 1.2rem;
  &:disabled {
    background-color: #808080;
  }
}
.btn-secondary-modal {
  @extend %btn;
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 1rem;
  margin-top: 10px;
  background-color: gray;
}
.btn-light {
  @extend %btn;
  font-family: $PRIMARY_TEXT;
  background-color: $LIGHT_COLOR;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}
.btn-secondary-outline {
  @extend %btn;
  font-family: $SECONDARY_TEXT;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid #808080;
  background-color: inherit;
  margin: 25px 0px;
}
.btn-cancel {
  background-color: white;
  padding: 8px 20px;
  border: 1px solid white;
  cursor: pointer;
  box-shadow: 2px 0px 0px 0px rgba(11, 245, 108, 0.1);
}
