.tenantdetailsboxin {
  flex: 80% 1;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto;
  word-break: break-word;
  grid-gap: 20px;
  margin-top: 27px;
}

.tenantdetailsboxinInvitation {
  flex: 80% 1;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto;
  word-break: break-word;
  grid-gap: 20px;
  margin-top: 27px;
}

.tenantdetailsbox {
  display: flex;
  align-items: space-between;
  justify-content: space-around;
}
