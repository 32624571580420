.modal {
  /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 12; /* Sit on top */
  padding-top: 85px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.modalCancel {
  /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 12; /* Sit on top */
  padding-top: 85px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.1); /* Black w/ opacity */
}
/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 10px 0 20px 0px;
  border: 1px solid #888;
  width: 90%;
  height: auto;
  overflow-y: auto;
  max-width: 500px;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.modal-content-cancel {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 10px 0 20px 0px;
  border: 1px solid #888;
  width: 90%;
  height: auto;
  overflow-y: auto;
  max-width: 500px;
  border-radius: 3px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: black;
  float: right;

  font-size: 28px;
  padding: 4px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: dodgerblue;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  color: black;
}

.modal-body {
  padding: 2px 16px;

  color: #808080;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.modal-text-input {
  padding: 8px 12px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 12px;
}

.text-footer-modal {
  color: black;
  opacity: 0.7;
  margin-bottom: 15px;
  font-size: 0.8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.footer-action-button {
  background-color: #808080;
  color: white;
  width: 100%;
  border: 0px;
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 15px;
}
