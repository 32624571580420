.tabs {
  display: grid;

  grid-template-columns: 1fr 1fr auto;
  background-color:#1876D1;
  font-family: sans-serif;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);

  h4.active {
 
    border-radius: 5px;
    color: $WHITE_COLOR;
   border-bottom: 2px solid #F07249;
   border-radius: 0px;
  }

  h4 {
    padding: 0.8rem;
    text-align: center;
    width: 100%;
    display: inline-block;
    cursor: pointer;
    color: #BED6F1
   
  }
}

.checkList-box {
  display: grid;
  padding: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-top: 2rem;

  .list-group {
    width: 100%;
    .list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: whitesmoke;
      padding: 0.6rem;
      border-radius: 5px;
      margin-bottom: 6px;
      .type {
        font-weight: bold;
      }
    }
  }
  .form {
    width: 75%;
    padding: 0.5rem 0.4rem 0 3.2rem;
  }
}

.checklist-table {
  border: 1px solid $PRIMARY_COLOR;
  th:first-child {
    width: 75%;
  }
  th {
    text-align: center;
    border-bottom: 1px solid $DARK_COLOR;
    border-right: 1px solid $DARK_COLOR;
    padding: 7px;
  }
  td {
    padding: 8px;
    border-right: 1px solid $DARK_COLOR;
    border-bottom: 1px solid $DARK_COLOR;
    cursor: pointer;
  }
  td .icon:hover {
    background-color: $CYAN;
    transition: all 0.3s;
    border-radius: 50%;
  }
}

.tab2 {
  margin-top: 10px;
  padding: 2rem;

  .tabs {
    h4.active {
      border-bottom: 5px solid lighten($PRIMARY_COLOR, 20%);
      background-color: $WHITE_COLOR;
      color: black;
      border-radius: 0px;
    }
  }
}
