.orderCheckListWrapper {
  display: flex;

  justify-content: space-between;
}
.timeanddate {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.dividerStatus {
  border: 1px solid #f3f3f3;
  margin: 15px 0px;
}
.statusWrapper {
  display: flex;
  justify-content: space-between;
}

.statusBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 25px;

  .statusTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */

    text-transform: uppercase;
    margin-bottom: 5px;
    color: #808080;
    margin-left: 20px;
  }
}
.statusBoxContainerFaded {
  display: flex;
  margin: 10px 0px;
  opacity: 0.2;
  align-items: center;
  .statusTitle {
    color: black;
  }
}
.statusBoxContainerFadedLess {
  display: flex;
  margin: 10px 0px;
  opacity: 0;
  align-items: center;
  .statusTitle {
    color: black;
  }
}
.statusBoxSecondChild {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.unloadingStatusWrapper {
  margin-left: 25px;
}

.loadingStatusWrapperFaded {
  opacity: 0.2;
}
.unloadingStatusWrapperFaded {
  margin-left: 25px;
}
