@import 'button';
@import 'config';
@import '../theme/themeColors.scss';
@import 'typography';
@import 'materialIcons';
@import 'welcome';
@import 'form/FormField';
@import 'utilities';
@import 'shipmentList.scss';
@import 'shipmentDetails.scss';
@import 'subHeader.scss';
@import 'navBar.scss';
@import 'chemical.scss';
@import 'pagination.scss';
@import 'order/orderList';
@import 'order/orderDetails';
@import 'checklist';
@import 'layout.scss';
@import 'addTank.scss';
@import 'configuration.scss';
@import 'fieldsUpdating.scss';
@import 'orderItem.scss';
@import '_sideDrawer.scss';
@import 'orderPicture.scss';
@import 'companyCard.scss';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  letter-spacing: 0.5px;
}

%flex {
  display: flex;
}

.flex-center-end {
  @extend %flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center-end-pad10 {
  @extend %flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.flex-center-self {
  @extend %flex;
  align-items: center;
}
.flex-center-date {
  @extend %flex;
  align-items: center;
}
.flex-center-diff {
  @extend %flex;
  flex-direction: row;
}

.flex-row {
  @extend %flex;
  flex-direction: row;
}

.flex-align-middle {
  @extend %flex;
  align-items: center;
  justify-content: center;
}
.flex-center-button-item {
  @extend %flex;
  align-items: center;
  padding: 8px 12px;
}

.flex-1 {
  flex: 1;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.h-100 {
  height: 100%;
}

.picker {
  width: 100%;
  padding: 10px 10px;
  border-radius: 3px;
  margin: 5px 0;
}
.screenStatusMessage {
  text-align: center;
  margin-top: 15px;
}
.orderTrack {
  width: 100%;
  border-top: 1px solid black;
  text-align: center;
  padding: 8px 12px;
  border-bottom: 1px solid black;
}

.table-head {
  display: flex;
  padding-left: 10px;
  background-color: $backgroundPrimary;
  border-radius: $BORDER_RADIUS_BUTTON;
  align-items: baseline;
  z-index: 0;
  position: sticky;
  top: 0;
  & > * {
    padding: 9px;
    color: $textMedium;
    font-weight: 700;
  }
}

.table-body {
  padding-bottom: 50px;
  .table-body-row {
    display: flex;
    margin: 15px 0;
    border: 1px solid #dedeed;
    border-radius: $BORDER_RADIUS_BUTTON;
    user-select: none;
    align-items: baseline;
    padding-left: 10px;
    cursor: pointer;
    &:hover {
      transition: 0.5s;
      background-color: $backgroundPrimary;
    }
    &:active {
      transition: 0.1s;
      background-color: $backgroundSecondary4;
    }
  }
  .table-body-column {
    padding: 20px 9px;
    align-self: center;
    overflow-wrap: break-word;
  }
}

.search-bar-container {
  border-radius: $BORDER_RADIUS;
  border: 1px solid #f3f3f3;
  transition: 0.3s;
  overflow: hidden;
  &:hover {
    box-shadow: 0 0 0 1px $secondaryLight;
  }
  &:focus-within {
    transition: 0.3s;
    box-shadow: 0 0 0 1px $secondaryMain;
  }
}

.search-bar {
  padding: 10px;
  border: none;
  outline: none;
  &::-webkit-input-placeholder {
    color: $textLight;
  }
}

.search-bar-icon {
  display: flex;
  justify-content: center;
  padding-left: 10px;
  align-items: center;
}

.pagination-bottom {
  position: sticky;
  bottom: 10px;
  background: #eef2f7;
  display: flex;
  border-radius: 9px;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
  padding-left: 15px;
}

.input-container {
  .input-label {
    font-weight: 500;
    margin-bottom: 5px;
    min-height: 20px;
    margin-top: 10px;
  }
  .multiple-autocomplete {
    .MuiTextField-root {
      padding: 0 13px 0 0;
    }
  }
  .form-text-input {
    width: 100%;
    padding: 13px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    outline: none;
    border: 0.2px solid #aca7a7b9;
    // &:hover {
    //   box-shadow: 0 0 0 2px $secondaryLight;
    // }
    // &:focus-within {
    //   transition: 0.3s;
    //   box-shadow: 0 0 0 1px $secondaryMain;
    // }
  }
  .form-text-input-2 {
    width: 100%;
    padding: 13px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    outline: none;
    border-right: none;
    border-top: 1.7px solid #f3f3f3;
    border-bottom: 1.7px solid #f3f3f3;
    border-left: 1.7px solid #f3f3f3;
    background: url(../assets/search.png) no-repeat scroll 10px 12px;
    padding-left: 35px;
  }
}

.input-container-cross {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.input-container-cross {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.form-checkbox-input {
  width: 100%;
  border: 1px solid #a3a2a2;
  padding-left: 13px;
  border-radius: 10px;
  display: flex;
  flex: 1;
}

.form-row {
  margin: 5px 0;
  & > * {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
      // margin-right: 40px;
      // margin-left: -100px !important;
      // margin-top: 40px !important;
    }
  }
}

.hidden {
  display: none !important;
}
