.button{
    border:none;
    padding:0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    display: inline-flex;
    justify-content: center;
}

.button:active{
    opacity: .8;
}