.loading {
  // display: inline;
  .wrapper {
    text-align: center;
  }
  .bubble {
    width: 10px;
    height: 10px;
    background-color: rgb(121, 121, 118);
    border-radius: 50%;
    margin: 0 3px;
    display: inline-block;
    -webkit-animation: in-and-out-bubbles 1.2s infinite ease-in-out;
    animation: in-and-out-bubbles 1.2s infinite ease-in-out;
  }
  .bubble1 {
    -webkit-animation-delay: -0.35s;
    animation-delay: -0.35s;
  }
  .bubble2 {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  @keyframes in-and-out-bubbles {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    20% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes in-and-out-bubbles {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    20% {
      -webkit-transform: scale(1);
    }
  }
}

.fullScreen {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
