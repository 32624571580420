.form-field {
  width: 100%;
  margin-top: 10px;

  .input-field {
    padding: 10px 8px;
    border: 1px solid grey;
    border-radius: 3px;
    outline: none;
    width: 100%;
    margin: 8px 0;
    box-sizing: border-box;
  }

  .input:focus {
    box-shadow: 0 0 2px grey;
  }

  .input-error {
    color: $DANGER;
    font-size: 12px;
    margin-bottom: 8px;
  }
}
