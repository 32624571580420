.welcome {
  height: 100vh;
  display: grid;
  background-color: white;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    background-color: $WHITE_COLOR;
    border-radius: 6px;
    letter-spacing: 1.8px;
    width: 100%;

    .image {
      display: flex;
      align-items: center;
      margin: auto;
      background-color: $PRIMARY_COLOR;
      clip-path: polygon(0 0, 94% 0, 81% 100%, 0% 100%);
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;

      img {
        height: 320px;
        width: 320px;
      }
    }

    .content {
      width: 34rem;
      text-align: center;
      padding-right: 7rem;
    }
  }

  .title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 13rem;
    letter-spacing: 2px;
    // box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    padding: 0.4rem;
    border-radius: 25px;
    span {
      color: $CYAN;
    }
  }

  .login-button {
    letter-spacing: 1.6px;
    width: 100%;
    font-size: 18px;
    cursor: pointer;
    box-shadow: none;
    padding: 16px;
    transition: all 0.3s;
    font-size: 20px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      border: 1px solid transparent;
      background-color: $CYAN;
      // box-shadow: 0px 5px 5px 0px rgb(0, 0, 0, 20%);
    }
  }
}

@media only screen and (min-width: 1450px) {
  .welcome {
    .container {
      .image {
        padding-left: 8rem;
        img {
          height: 450px;
          width: 400px;
        }
      }
      .title {
        font-size: 44px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .welcome {
    .container {
      .image {
        display: none;
      }
      .content {
        padding: 14px;
        padding-right: 14px;
      }
    }
  }
}
