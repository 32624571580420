.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
.material-icons.verticalAlign {
  vertical-align: -10px;
  padding-right: 5px;
}
/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}
.material-icons.cursor {
  &:hover {
    cursor: pointer;
  }
}
.material-icons.blueLight {
  color: #66ccff;
}
.material-icons.blueLight.md-36 {
  color: #66ccff;
  font-size: 36px;
}
