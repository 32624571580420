.shipment-details {
  .shipment-textfield {
    margin-top: 5px;
  }
  .details {
    padding: 18px;

    span {
      color: $DARK_COLOR;
    }
    p {
      font-size: 15px;
      color: $GREY;
      padding: 5px;
      span {
        margin-left: 10px;
        font-weight: 600;
      }
    }
    h5 {
      font-weight: 550;
      padding: 0.1rem;
    }
  }
  .options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin: 15px;
    grid-gap: 10px;
    > * {
      margin: 0;
    }
  }
}
.input-box {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 5px 0px;
  font-weight: 400;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid grey;
}
#input-box-icons {
  font-size: 20px;
  opacity: 0.5;
}
#input-box-icons-editable {
  font-size: 20px;
  cursor: pointer;
}
.dragged {
  background-color: #1e90ff10;
}

.dragging {
  background-color: #1e90ff20;
  border: 2px dashed !important;
}

.loader-animated {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #27353f; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ashutosh 2s linear infinite;
}

@keyframes ashutosh {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.shipment-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.shipmentContainer {
  margin-bottom: 10px;
}

.flex-shipment {
  display: flex;
}
.flex-shipment > div:nth-child(1) {
  margin-right: 10px;
}
.flex-shipment > div {
  width: 100%;
}
.shipment-label {
  font-weight: bold;
  font-size: 1rem;
}

/* bol-tabs */

.bol-list-tab {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid $DARK_COLOR;
    cursor: pointer;
    margin-bottom: 4px;
    border-radius: 3px;

    button {
      padding: 3px 10px;
      letter-spacing: 0.5px;
      background-color: $PRIMARY_COLOR;
      border-radius: 6px;
      color: white;
      cursor: pointer;
      border: none;
    }
  }

  li.active {
    border: none;
    background-color: lighten($PRIMARY_COLOR, 15%);
    color: white;
  }
}
.dialog-success-message{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  flex-direction: column;
}