.browselogo {
  position: absolute;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: grey;
  cursor: pointer;
}
.edittenant {
  height: 100;
  width: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.edittenantimage {
  width: 120px;
  height: 120px;
  background: #ffffff;
  border: 1px solid #3399ff;
  box-sizing: border-box;
  border-radius: 7px;
  object-fit: contain;
}
.removelogo {
  padding-top: 20px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
  text-decoration-line: underline;
  color: #e92a2a;
}
