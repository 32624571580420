.fieldsWrapper
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.fieldParent{
 margin-bottom: 10px;
 margin-left: 2px;
}
.fieldsWrapper>div{
    border: 1px solid black;
    padding: 6px 10px;
    font-size: 12px;
    letter-spacing: 0.4px;
   
}