.filtersTitle{
    margin-bottom: 10px;
    padding-left: 10px;
    color: dodgerblue;
}
.keyTitle{
    font-size: 14px;
    margin-top: 10px;
    padding-left: 10px;
}
.keyValue{
    margin-top: 10px;
    padding-left: 10px;
}
.filterContainer{
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
}
.selectedFilters{

    position:absolute;
    width: 100%;
    height: 250px;
    bottom: 0px;
    .title{
        font-style: normal;
        padding:5px 10px;
        font-weight: 500;
        color:#3F50B5
    }
    .keyValuePair{
        display: grid;
        padding:5px 10px;
      margin-bottom: 10px;
      row-gap: 10px;
    

       
    }
    

}
.statusBubble{
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   column-gap: 6px;
}

.bubble{
    background-color: #BEE1F1;
    padding: 10px 12px;
    border-radius: 25px;
    font-size: 12px;
    margin-bottom: 9px;
    text-align: center;
}
.filterButton{
    display: grid;
    grid-template-columns: 1fr 1fr;
   margin: 5px 10px;
    column-gap: 20px;
}