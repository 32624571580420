.cardBorder {
  border: 1px solid #003366;
  padding: 15px;
  margin: 5px;
  color: #808080;
}
.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}
.cardFooterItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statusButtonActive {
  display: inline-block;
  padding: 6px 8px;
  font-size: 14px;
  margin-top: 5px;
  background-color: mediumseagreen;
  color: white;
  border-radius: 3px;
}

.statusButtonInActive {
  display: inline-block;
  padding: 6px 8px;
  font-size: 14px;
  margin-top: 5px;
  background-color: #de5f5f;
  color: white;
  border-radius: 3px;
}
.company-list {
  display: grid;
  padding: 24px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.company-logo {
  height: 30px;
  max-width: 100%;
  object-fit: contain;
}
.profimagelist {
  height: 38;
  width: 38;
  margin-right: 10;
  border-radius: 50%;
  object-fit: cover;
}

.superadminlistbox {
  padding: 10px;
  border: 1px solid #f3f3f3 !important;
  border-radius: 7px !important;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(135, 135, 135, 0.3);
  &:hover {
    box-shadow: 0px 3px 12px #ECF1FF !important;
    transition: all 0.3s;
    cursor: pointer;
  }
}
.superadminactiveinactive{
font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 18px;
text-align: right;
color: #60B077;

}