.add-tank-steps {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  height: 100%;
  overflow: auto;

  .steps {
    flex-grow: 0;
    //   border-right: 1px solid darkgray;
    padding: 14px;
    position: sticky;
    top: 0;
    align-self: flex-start;
    height: 600px;

    li {
      padding: 12px;
      display: flex;
      align-items: center;

      .step-number {
        border-radius: 50%;
        width: 34px;
        height: 34px;
        padding: 9px;
        background: darkgray;
        color: #000;
        text-align: center;
        margin-right: 10px;
      }
    }

    li.active {
      .step-number {
        background-color: $PRIMARY_COLOR;
        color: $WHITE_COLOR;
      }
    }
  }

  hr {
    position: sticky;
    top: 0;
  }

  .content {
    flex-grow: 4;
    padding: 30px;
    overflow: auto;
    margin-bottom: 20px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 14px 6%;
  background-color: $WHITE_COLOR;
  width: 100%;

  button {
    padding: 6px 30px;
    margin-left: 14px;
    font-size: 15px;
  }

  .back-button {
    background-color: $GREY !important;
  }
}

.heading {
  color: #707070;
  font-weight: bold;
  padding-bottom: 10px;
}
.isCustomer {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.inputAndSelector {
  display: flex;
  width: 100%;
  justify-content: space-between;
  & > div:first-child {
    width: 60%;
  }
  & > div:nth-child(2) {
    width: 35%;
  }
}

.addTakeStepOneComponent {
  .info-field {
    .tank-field {
      .form-field {
        margin-top: 4px;
      }
    }
  }
  .input-number {
    input {
      width: 100%;
      height: 38px;
      padding: 8px 10px;
      border: 1px solid black;
      border-color: #999999;
      border-radius: 3px;
      margin: 5px 0;
    }
  }
}

.input-numbers {
  input {
    width: 100%;
    height: 38px;
    padding: 8px 10px;
    border: 1px solid black;
    border-color: #999999;
    border-radius: 3px;
    margin: 5px 0;
  }
}

.chemicalSuggestion {
  box-shadow: 0 4px 6px rgba(32, 33, 36, 0.28);
  overflow: auto;
  width: 100%;
  max-height: 200px;
  ul {
    display: block;
    margin-inline-start: 0px;
    list-style: none;
    max-height: 200px;
    li {
      padding: 10px;
      word-break: break-all;
      font-weight: 100;
      user-select: none;
    }
    li:hover {
      background-color: #eceaea;
    }
    li:active {
      background-color: #d4d3d3;
    }
    .horizontalLine {
      height: 2px;
      background-color: #e9e8e9;
      width: 90%;
      margin-left: 10px;
    }
  }
}

// .addTakeStepTwoComponent {
// }

.addTakeStepThreeComponent {
  .arrow-back {
    margin-top: -26px;
    cursor: pointer;
  }
  padding: 10px;
  .inputAndSelector {
    padding: 20px 0 0 0;
  }
  .view-edit {
    font-weight: normal;
    text-decoration-line: underline;
    font-size: small;
  }
}

.AddPersons {
  .addText {
    color: #797979;
    font-size: 15px;
    font-weight: lighter;
    margin: 10px 0px;
  }
  .line {
    background-color: #808080;
    height: 2px;
    border: none;
    margin: 5px 0px;
  }
  .added-emails {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
    p {
      color: #797979;
      font-size: 15px;
      font-weight: lighter;
    }
    .delete-icon {
      cursor: pointer;
    }
  }

  .input-email {
    display: flex;
    justify-content: space-between;
    input {
      padding: 6px 6px;
      width: 78%;
    }
    .btn-primary {
      background: #707070;
      box-shadow: none;
      width: 20%;
      margin: 0;
    }
    .loading {
      align-self: center;
    }
  }
  .emailSuggestion {
    box-shadow: 0 4px 6px rgba(32, 33, 36, 0.28);
    overflow: auto;
    width: 78%;
    ul {
      display: block;
      margin-inline-start: 0px;
      li {
        margin: 10px;
        word-break: break-all;
      }
      .horizontalLine {
        height: 2px;
        background-color: #e9e8e9;
        width: 90%;
        margin-left: 10px;
      }
    }
  }
  .finish-button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration-line: underline;
    color: #707070;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 20px;
  }
}

.AddSharedTanks {
  .addText {
    color: #797979;
    font-size: 15px;
    font-weight: lighter;
    margin: 0px 0px;
  }
  .div1 {
    display: flex;
    padding: 10px 0px;
  }
  .tankNames {
    padding-left: 10px;
  }
  .finish-button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration-line: underline;
    color: #707070;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 20px;
  }
}
.addTakeStepFiveComponent {
  .checkedFields {
    .inputAndSelector {
      padding: 20px 0;
    }
  }
}

.addTakeStepSixComponent {
  .checkedFields {
    .inputAndSelector {
      padding: 20px 0;
    }
  }
  .timing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .inputTime {
      width: 40%;
      border: 1px solid #000000;
      .material-icons {
        vertical-align: middle;
      }
      input[type='time'] {
        width: 100%;
        font-size: 18px;
        border: none;
        text-align-last: center;
        flex-direction: row-reverse;
        &::-webkit-datetime-edit-text {
          padding: 20px 4px;
        }
        &::-webkit-calendar-picker-indicator {
          border-right: 2px solid #707070;
          height: 20px;
          padding-right: 10px;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .timingError {
    display: flex;
    justify-content: space-between;
    p {
      width: 40%;
      word-break: break-all;
    }
  }
}

.arrow-back {
  margin-top: -18px;
  cursor: pointer;
}

.step7 {
  .add-field {
    display: flex;
    justify-content: flex-end;

    button {
      padding: 8px;
      display: flex;
      align-items: center;
    }

    .disable-add-field {
      background-color: $LIGHT_COLOR !important;
      color: black !important;
    }
  }

  .custom-fields {
    display: flex;
    flex-direction: row;
    align-items: center;

    .form-field {
      margin-right: 10px;
    }

    .close-icon {
      margin-top: 28px;
      cursor: pointer;
    }
  }
}

.raise-error{
  animation: error 1s ease-in-out;
}
@keyframes error {
  0%{
    transform: scale(1.1);
    color: $errorMain;
  }
  30%{
    transform: scale(1);
  }
  100%{
    color: $secondaryMain;
  }
}
