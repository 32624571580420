.p-1 {
  padding: 5px;
}

.p-2 {
  padding: 10px;
}

.p-3 {
  padding: 15px;
}

.p-4 {
  padding: 20px;
}

.p-none {
  padding: 0;
}

.pt-1 {
  padding-top: 5px;
}

.pt-2 {
  padding-top: 10px;
}

.pt-3 {
  padding-top: 15px;
}

.pt-4 {
  padding-top: 20px;
}

.pt-none {
  padding-top: 0;
}

.pb-1 {
  padding-bottom: 5px;
}

.pb-2 {
  padding-bottom: 10px;
}

.pb-3 {
  padding-bottom: 15px;
}

.pb-4 {
  padding-bottom: 20px;
}

.pb-none {
  padding-bottom: 0;
}

.px-1 {
  padding-left: 5px;
  padding-right: 5px;
}
.px-2 {
  padding-left: 10px;
  padding-right: 10px;
}

.py-1 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.py-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.m-1 {
  margin: 5px;
}

.m-2 {
  margin: 10px;
}

.m-3 {
  margin: 15px;
}

.m-4 {
  margin: 20px;
}

.mx-1 {
  margin-left: 5px;
  margin-right: 5px;
}

.mx-2 {
  margin-left: 10px;
  margin-right: 10px;
}

.my-1 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mt-1 {
  margin-top: 5px;
}
.mt-2 {
  margin-top: 10px;
}
.mt-3 {
  margin-top: 15px;
}
.mt-4 {
  margin-top: 20px;
}

.ml-1 {
  margin-left: 5px;
}
.ml-2 {
  margin-left: 10px;
}

.mr-1 {
  margin-right: 5px;
}
.mr-2 {
  margin-right: 10px;
}

.border-1 {
  border: 1px solid;
}

.font-bold {
  font-weight: bold !important;
}

.font-normal {
  font-weight: normal;
}

.button-base {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
}
