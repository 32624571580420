.table-pager-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 2px 10px;
  margin: 0 10px 5px 10px;
  // border-radius: $BORDER_RADIUS;
  background-color: $backgroundPrimary;
  // background-color: white;

  .pagers {
    display: flex;
    button {
      font-size: 0.8125em;
      border: 0;
      border-radius: 0.125rem;
      height: 1.75em;
      padding: 0px 8px;
    }
    button:hover {
      background-color: lighten($PRIMARY_COLOR, 25%);
    }
    button.current {
      background-color: $PRIMARY_COLOR; // $green;
      color: #fff;
    }
  }
}

.table-pager-wrap .pagers div + div {
  margin-left: 0.5rem;
}

.table-pager-wrap .pagers {
  display: flex;
  align-items: center;
}
