.order-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding-top: 5px;
}

.order-list-card {
  background-color: $WHITE_COLOR;
  padding: 0.8rem;
  border: 1px solid $DARK_COLOR;
  border-radius: 6px;
  margin: 8px;
  cursor: pointer;

  div {
    display: flex;
    color: #808080;
    justify-content: space-between;
    padding: 7px;
  }
  div span {
    color: $PRIMARY_COLOR;
  }
}
