.tenantlogo {
  padding: 5px;
  width: 150px;
  height: 150px;
  background: #ffffff;
  border: 1px solid #3399ff;
  box-sizing: border-box;
  border-radius: 15px;
  object-fit: contain;
}
.mycompanyboxin {
  flex: 80%;
  display: grid;
  align-content: space-between;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
}
.mycompanybox {
  display: flex;
  align-items: space-between;
  justify-content: space-around;
}
