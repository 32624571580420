@import '../../styles/config';

$box-shadow: 0 1px 2px rgba(0, 0, 0, 0.002), 0 1px 1px rgba(0, 0, 0, 0.14);
$tracer: 1px solid blue;
// border: $tracer;

.search {
  .dropdown {
    position: relative;
    .action {
      border-radius: 5px;
      padding: 1px;
      min-width: 500px;
      // box-shadow: $box-shadow;
    }
    .action:focus-within .contains {
      display: block;
    }

    &:focus-within .contains {
      display: block !important;
    }
    div:focus .contains {
      display: block !important;
    }
  }

  .contains {
    justify-content: start;
    width: 70%;
    background-color: white;
    max-height: 320px;
    overflow-y: scroll;
    box-shadow: $box-shadow;
    border-radius: 0 0 5px 5px;
  }

  .search-wrap {
    width: 70%;

    .icon {
      cursor: pointer;
      vertical-align: middle;
      border-radius: 50%;
      width: 27px;
      height: 27px;
      vertical-align: middle;
      justify-content: center;
      background-color: #f1f1f1;
    }
    .icon:active {
      opacity: 0.5;
    }

    .input-group {
      display: flex;
      width: inherit;
      border-radius: 5px;
      background-color: #f1f1f1;
      .apart {
        width: inherit;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .input {
        width: inherit;
        border: none;
        outline: none;
        margin: 0px 0px;
        padding: 10px 8px;
        background-color: #f1f1f1;
      }

      .dash-loading {
        position: relative;
        width: inherit;
        overflow: hidden;
        max-width: inherit;
        display: block;
        height: 3px;
      }
      .dash-loading:before {
        content: '';
        position: absolute;
        left: -50%;
        height: 3px;
        width: 40%;
        background-color: dodgerblue;
        animation: toRight 1s linear infinite;
      }
    }

    .card {
      width: 142%;
      animation: fadeUp 0.1s linear;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      border-bottom: 1px solid rgba($color: gray, $alpha: 0.3);
      padding: 8px 14px;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      font-size: 12px;
      background-color: white;

      .row {
        display: flex;
        padding: 3px 8px 3px 0px;
      }
      .column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px;
      }
      span {
        border: 1px solid rgba(#2471a3, 0.1);
        border-radius: 4px;
        margin-right: 8px;
      }
    }
    .card:hover {
      background-color: #1e90ff10;
    }

    .results {
      font-size: 12px;
      width: fit-content;
      overflow-x: scroll;

      .result_heading {
        padding: 0px 20px;
        font-weight: bold;
      }

      .not-found-text {
        font-size: 13px;
        font-weight: bold;
        color: darkgray;
        padding: 50px;
        display: flex;
        text-align: center;
        justify-content: center;
      }
      .list {
        margin-top: 10px;
        border: 1px solid $DARK_COLOR;
        border-left: 8px solid $CYAN;
        cursor: pointer;

        .details {
          padding: 5px 10px;
        }

        .pnumber {
          font-weight: 600;
          padding: 4px 0;
        }

        p {
          color: $GREY;
          font-size: 13px;
          padding: 4px 0;
        }
        hr {
          margin: 2px 0;
        }
      }
    }
  }
  .flex {
    display: flex;
    // width: inherit;
  }
  .user-profile-back {
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 50%;
    background-color: #2471a3; // fallback

    [data-initial]:before {
      display: inline-block;
      line-height: 30px;
      vertical-align: middle;
      text-align: center;
      content: attr(data-initial);
      width: 30px;
      height: 30px;
      font-size: 14px;
      font-weight: bold;
      color: white;
      text-transform: uppercase;
    }
  }

  @keyframes toRight {
    0% {
      left: -50%;
    }
    50% {
      left: 20%;
      width: 80%;
    }
    100% {
      left: 100%;
      width: 100%;
    }
  }

  @keyframes fadeUp {
    0% {
      transform: translateY(5%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  @media screen and (max-width: 600px) {
    .dropdown {
      .action {
        max-width: 100%;
      }
    }
    .search-wrap {
      display: inline-block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9999;
      overflow: auto;
      padding: 12px 5px;
      background-color: white;
    }
    .dropdown {
      .contains {
        position: relative;
        box-shadow: none;
        max-height: 100%;
        display: block !important;
        div {
          overflow-x: hidden;
        }
      }
    }
  }
}
