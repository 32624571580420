.ordersTable {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  td,
  th {
    padding: 10px;
    text-align: center;
    font-size: 12px;
    color: #353434;
  }
}

.orderDetailsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 20px;
  grid-gap: 30px;
  div p {
    font-size: 15px;
    line-height: 20px;
  }
  .heading {
    color: black;
  }
}
