// paper & background
$paper: #ffffff;

// primary
$primaryLight: #e3f2fd;
$primaryMain: #003366;
$primaryDark: #022547;

// secondary
$secondaryLight: #e9f4fe;
$secondaryMain: #3298ff;
$secondaryDark: #0457aa;

$color_green: #accb4b;
$color_orange: #ee8c49;
$color_grey: #636363;

// error
$errorMain: #f44336;

// grey
$backgroundPrimary: #eef2f7;
$backgroundSecondary: #e9f4fe;
$backgroundSecondary2: #3399ff80;
$backgroundSecondary3: #3298ff40;
$backgroundSecondary4: #3298ff26;

$textMedium: #636363;
$textDark: #1a1a1a;
$textLight: #bababa;

$borderColor1: #a3a2a2;

// ===========================|| JAVASCRIPT ||=========================== //

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;

  // secondary
  secondaryLight: $secondaryLight;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;

  // error
  errorMain: $errorMain;

  colorGreen: $color_green;
  colorOrange: $color_orange;
  colorGrey: $color_grey;

  // Text
  textLight: $textLight;
  textMedium: $textMedium;
  textDark: $textDark;

  // background
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  backgroundSecondary2: $backgroundSecondary2;
  backgroundSecondary3: $backgroundSecondary3;
  backgroundSecondary4: $backgroundSecondary4;

  // util

  white: #ffffff;
  black: #000000;

  borderColor1: $borderColor1;
}
