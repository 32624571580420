.nav-bar {
  background-color: $WHITE_COLOR;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: -4px 2px 4px 0px #e0dede;
  z-index: 10;
  width: 100%;

  .title-logo {
    font-size: 1.5rem;
    letter-spacing: 1.2px;
    font-weight: 900;
  }

  .logoImage {
    height: 3.2rem;
    max-width: 250px;
  }

  .sideBar {
    position: fixed;
    z-index: 10;
    height: 100vh;
    left: 0;
    top: 0;
    transition: 0.2s;
    overflow-x: hidden;
    background-color: $WHITE_COLOR;
    padding: 4px;
    width: 60%;
    max-width: 300px;
    box-shadow: 0 0 10px grey;

    ul {
      margin-top: 20px;
    }

    ul li {
      list-style-type: none;
      padding: 10px;
    }

    ul li:nth-child(odd) {
      background-color: $LIGHT_COLOR;
    }

    ul li:hover {
      background-color: $PRIMARY_COLOR;
      color: $WHITE_COLOR;
      cursor: pointer;
    }
  }

  .show {
    transform: translateX(0%);
  }
  .hide {
    transform: translateX(-100%);
  }

  @media only screen and (min-width: 768px) {
    .nav-bar {
      .menu {
        display: none;
      }
      .sideBar {
        display: none;
      }
    }
  }
}

.aside {
  font-size: 15px;
}

.nav-links {
  box-sizing: border-box;
  a {
    background-color: $WHITE_COLOR;
    margin-bottom: 3px;
    padding: 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #707070;
    cursor: pointer;
    min-height: 50px;

    img {
      height: 30px;
      object-fit: contain;
    }
  }

  // a:active {
  //   background-color: #e0dede;
  // }

  .active-route {
    background-color: #e0dede;
    border-right: 5px solid dodgerblue;
  }
  .active-route-special {
    border-right: 4px solid dodgerblue;
  }

  .mainLinks {
    background-color: #cad6e280;
    padding: 15px;
    margin-bottom: 3px;
    color: black;
    img {
      height: 30px;
      object-fit: contain;
    }
  }
}

@media only screen and (max-width: 600px) {
  .aside {
    display: none;
  }
  .nav-bar {
    .title-logo {
      font-size: 1.2rem;
    }

    .logoImage {
      height: 1.5rem;
      margin-right: 0px;
    }
  }
}

.nav-bar-items {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;

  @media only screen and (min-width: 480px) {
    .search-icon {
      display: none;
    }
  }
}
