$PRIMARY_COLOR: #003366;
$SECONDARY_COLOR: #808080;
$MEDIUM_COLOR: #6e6969;
$LIGHT_COLOR: #f8f4f4;
$DARK_COLOR: #0c0c0c;
$WHITE_COLOR: #ffffff;
$DANGER_COLOR: #ff5252;
$CYAN: #66ccff;
$DANGER: #ff5252;
$GREY: #a6a6a6;

$BORDER_RADIUS: 7px;
$BORDER_RADIUS_BUTTON: 9px;

$PRIMARY_TEXT: Arial, Helvetica, sans-serif;
$SECONDARY_TEXT: 'Lato', sans-serif;

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@function set-text-color($color) {
  @if (lightness($color) >70) {
    @return #333;
  } @else {
    @return #fff;
  }
}

@mixin set-background($color) {
  background-color: $color;
  color: set-text-color($color);
}
