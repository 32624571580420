@import 'config';
.text-primary-white {
  font-family: $PRIMARY_TEXT;
  color: white;
  font-size: 1.2rem;
}

.text-secondary{
  color: $SECONDARY_COLOR;
}

.text-secondary-gray {
  font-family: $PRIMARY_TEXT;
  letter-spacing: 0.5px;
  color: $SECONDARY_COLOR;
  font-size: 1.1rem;
}
.text-secondary-small {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: $SECONDARY_COLOR;
  font-size: 12px;
}
.text-secondary-content {
  font-family: Arial, Helvetica, sans-serif;
  color: $SECONDARY_COLOR;
  font-size: 14px;
  margin-top: 8px;
}
.input-error {
  color: #ff5252;
  font-size: 12px;
  margin-bottom: 8px;
}
