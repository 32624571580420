.orderPicture{
    border-radius: 4px;
    width: 100px;
    height: 100px;
//    transition: width 0.2s;
transition: transform .25s, visibility .25s ease-in;
//    transition: all .08s ease-in-out; 
}
.pictureInnerWrapper
{
    margin: 10px;
    padding: 5px;
    cursor: pointer;
}

.pictureWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    
    margin:20px 0px 20px;
 
}
.pictureWrapperScrollable{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    
    margin:20px 0px 20px;
    height: 600px;
    overflow-y: scroll;
}
.addIconWrapper{
    position: absolute;
    bottom: 20px;
    right: 20px;
   transition: all 0.2s ease-in;
    border-radius: 50%;
    .addIcon{
        font-size: 64px;
        color:#F21170;
        border-radius: 50%;
        background-color: white;
    }

    :hover{
    font-size: 72px;
    cursor: pointer;
    right: 25px;
    bottom: 25px;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,0.2);
    }
}

.uploadIcon{
    font-size: 64px;
}

.orderPictureWrapper{
    position: relative;
}

.outerParentWrapper{
    text-align: right;
  position: absolute;
  z-index: 10;
  right: 0px;
  top: 5px;
}